import React from 'react'
import styles from './CommentAnalysis.module.css';
import AnalysisBlock from './AnalysisBlock2';

const CommentAnalysis = ({ data }) => {
  let parsedData;
  if (typeof data === 'string') {
    try {
      parsedData = JSON.parse(data);
    } catch (err) {
      console.log('Error parsing data: ', parsedData);
      parsedData = {};
    }
  } else {
    parsedData = data
  }

  return (
    <div className={styles.analysisContainer}>
      <div className={styles.mainArea}>
        <div className={styles.analysisBlocks}>
          {Object.keys(parsedData).map(key => <AnalysisBlock key={key} sentiment={key} sentimentData={parsedData[key]}/>)}
        </div>
      </div>
    </div>
  )
}

export default CommentAnalysis;