import React, { useState, useEffect } from 'react'
import styles from './Main.module.css';
import Header from './Header'
import Hero from './Hero';
import Dashboard from './Dashboard';
import LeftPanel from './LeftPanel';
import { get } from 'aws-amplify/api';


const Main = ({ onSignOut, user }) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState('dashboard');
  const [reports, setReports] = useState([]);
  const [reportData, setReportData] = useState(null); // Add state for report search history


  const checkScroll = () => {
    if (!showScrollToTop && window.scrollY > 200) {
      setShowScrollToTop(true);
    } else if (showScrollToTop && window.scrollY <= 200) {
      setShowScrollToTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const getSearchHistory = async () => {
    try {
      const apiResponse = await get({
        apiName: 'march21DashboardGetReport',
        path: `/report/${localStorage.getItem('username')}`
      });
      const response = await apiResponse.response;
      const responseBody = await response.body.json();
      responseBody.sort((a, b) => new Date(b.report_created) - new Date(a.report_created));
      setReports(responseBody);

    } catch (e) {
      console.log("Error when fetching search history: \n" + e);
    }
  }

  // Get search history on load
  useEffect(() => {
    getSearchHistory();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
    return () => window.removeEventListener('scroll', checkScroll);
  }, [showScrollToTop]);

  return (
    <div className={styles.mainContainer}>
      <Header onSignOut={onSignOut} user={user}/>
      <div className={styles.content}>
        <LeftPanel setSelectedPanel={setSelectedPanel} setReportData={setReportData} reports={reports} getSearchHistory={getSearchHistory}/>
        <div className={styles.verticalLine}></div>
        {selectedPanel === 'dashboard' && <Dashboard user={user} reportData={reportData} getSearchHistory={getSearchHistory}/>}
        {selectedPanel === 'videoSearch' && <Hero user={user}/>}
      </div>
    </div>
  )
}

export default Main