import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { post } from 'aws-amplify/api';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import DOMPurify from 'dompurify';
import styles from './Login.module.css';

Amplify.configure(awsconfig);

const LoadingAnimation = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.loadingAnimation}></div>
    </div>
);

const Login = ({ setAuth }) => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const { username, password } = inputs;

  const onChange = e =>
    setInputs({ ...inputs, [e.target.name]: e.target.value });

  const onSubmitForm = async e => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    try {
      const sanitizedUsername = DOMPurify.sanitize(username);
      const sanitizedPassword = DOMPurify.sanitize(password);
      const restOperation = await post ({
        apiName: 'march21HandleLogin',
        path: '/login',
        options: {
          body: {
            sanitizedUsername, sanitizedPassword
          }
        }
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      const token = response.jwtToken;

      if (token) {
        localStorage.setItem('orangeToken', token);
        localStorage.setItem('username', username);
        setAuth(true);
        navigate('/dashboard');
      } else {
        setAuth(false);
        toast.error('Username or Password is incorrect');
      }
    } catch (err) {
      console.error(err.message);
      toast.error('Login failed. Please try again.');
    }
    setIsLoading(false); // Stop loading
  };

  return (
    <div className={styles.loginContainer}>
      <ToastContainer />
      <div className={styles.orangeAi}>Orange AI</div>
      <form onSubmit={onSubmitForm} className={styles.loginForm}>
      <h1 className={styles.loginText}>Login</h1>
        <div className={styles.inputGroup}>
          <span className={styles.formInputHeading}>Username</span>
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={username}
            onChange={onChange}
            className={styles.formInput}
          />
        </div>
        <div className={styles.inputGroup}>
          <span className={styles.formInputHeading}>Password</span>
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={onChange}
            className={styles.formInput}
          />
        </div>
        <button className={styles.submitButton} disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Log in'}
        </button>
        {isLoading && <LoadingAnimation />}
        <div className={styles.footer}>
            Questions? Email at <a href="mailto:amiteshwar@useorange-ai.com">amiteshwar@useorange-ai.com</a>
        </div>
      </form>
    </div>
  );
};

export default Login;
