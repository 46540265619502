import React, { useState, useRef, useEffect } from 'react';
import styles from './Hero.module.css';
import CommentAnalysis from './CommentAnalysis';
import { toast, ToastContainer } from 'react-toastify';
import { post, get } from 'aws-amplify/api';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

const InputField = ({ id, label, value, onChange, onClear, placeholder }) => (
    <>
      <div className={styles.form}>
        <label htmlFor={id}>{label}</label>
        <input 
          type="text" 
          id={id} 
          value={value} 
          onChange={onChange} 
          placeholder={placeholder}
        />
        {value && (
          <span onClick={onClear} className={styles.clearIcon}>
            X
          </span>
        )}
      </div>
    </>
);

const LoadingAnimation = () => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.loadingAnimation}></div>
    </div>
);

const Hero = ({ user }) => {
    const [videoLink, setVideoLink] = useState(''); //the video link for the YouTube video
    const [isLoading, setIsLoading] = useState(false); //for showing the Loading Text & Loading Animation while data is fetched & analyzed in the backend
    const [statusText, setStatusText] = useState(''); //for showing the Loading Text
    const [resultData, setResultData] = useState({}) //getting JSONdata from the backend
    const [videoData, setVideoData] = useState({}) //getting data about stats from the nested object
    const reportIdRef = useRef(null); // Ref to store the current reportId
    const intervalIdRef = useRef(null);

    const handleChange = (event) => {
        setVideoLink(event.target.value);
    };

    const handleClear = () => {
        setVideoLink('');
    }

    // Adjust label and icon positions based on resultData
    const labelStyle = Object.keys(resultData).length > 0 ? { marginTop: '-2%' } : { marginTop: '11%' };
    const clearIconStyle = Object.keys(resultData).length > 0 ? { top: '68%' } : { top: '91%' };


    const pollReportStatus = async (reportId) => {
      try {
        const reportId = reportIdRef.current;
        const pollingGetCall = await get({
          apiName: 'march21DashboardGetReport',
          path: `/report/${reportId}`
        });
        const pollingResponse = await pollingGetCall.response;
        const responseBody = await pollingResponse.body.json();

        if (responseBody.status === 'COMPLETED') {
          // Fetch the report from the reportUri or update the state with the URI
          setResultData(responseBody.reportContent);
          setVideoData(responseBody.videoStatistics);
          setIsLoading(false);
          setStatusText('');
          return true;
        } else if (responseBody.status === 'FAILED') {
          // Handle a failed report generation
          setIsLoading(false);
          setStatusText('');
          alert(`Sorry ${user}, but an error occured :( Would you mind quickly writing this to us so we can improve Orange AI for you?!`);
          setVideoLink('')
          return true;
        } else {
          return false;
        }
        // If status is IN_PROGRESS or any other status indicating waiting, do nothing
      } catch (error) {
          console.error('Error polling report status: ', error);
          setIsLoading(false);
          return true
      }
    };

    const handleSearch = async (event) => {
      event.preventDefault();
      if (!videoLink) {
        // Ideally, use a more integrated UI feedback mechanism
        toast.error('Please enter a YouTube video link.');
        return;
      }

      //Diplay Loading message & start loading
      setIsLoading(true); // Show loading indicator
      setStatusText('Preparing your insights...')

      try {
        const getCall = await post ({
          apiName: 'march21DashboardCreateReport',
          path: '/create-report',
          options: {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('orangeToken')}`
            },
            body: {
              "videoUrl": videoLink,
              "user": user,
            }
          }
        });
        const apiResponse = await getCall.response;
        const responseBody = await apiResponse.body.json(); //apiResponse returns a promise so await to let that resolve
        reportIdRef.current = responseBody.reportId;

        //Begin polling for report status
        intervalIdRef.current = setInterval(async () => {
          const isComplete = await pollReportStatus(reportIdRef.current);
          if (isComplete) {
            clearInterval(intervalIdRef.current); //Stop polling when the report is ready
          }
        }, 5000) //polling every 5 seconds
      } catch (err) {
        console.log("Error getting data from the backend: ", err)
        alert(`Sorry ${user}, but an error occured :( Would you mind quickly writing this to us so we can improve Orange AI for you?!`);
        setVideoLink('')
        setStatusText('') //resetting the status text in case of error as well
      }
    };

    const handleShare = () => {
        console.log("Sharing with team");
        // Add share functionality here
    };

    useEffect(() => {
        //Cleanup polling interval when the component is unmounted or reportId changes
        return () => {
          if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
          }
        };
    }, [])

    return (
      <div className={styles.heroContainer}>
        <ToastContainer />
        <div className={styles.form}>
          <InputField
            id="youtubeVideoLink"
            label={<span className={styles.formLabel}>Hey {user}!<br />Which video do you want to search today?</span>}
            value={videoLink}
            onChange={handleChange}
            onClear={handleClear}
            placeholder="Enter the YouTube Video Link here"
          />
        </div>
        {!isLoading && (<div className={styles.buttonGroup}>
          <button type="button" className={styles.searchButton} onClick={handleSearch}>
            AI Search by Orange
          </button>
          <button type="button" className={styles.shareButton} onClick={handleShare}>
            Send to Notion <span className={styles.comingSoon}>(coming March 29)</span>
          </button>
        </div>
        )}
        {isLoading && <LoadingAnimation />}
        {isLoading && <div className={styles.loadingText}>{statusText}</div>}
        <style jsx>{`
          .${styles.form} label {
            margin-top: ${labelStyle.marginTop};
          }
          .${styles.clearIcon} {
            top: ${clearIconStyle.top};
          }
        `}</style>
        {!isLoading && Object.keys(resultData).length > 0 && <CommentAnalysis data={resultData} videoStatData={videoData}/>}
      </div>
  )
}

export default Hero
