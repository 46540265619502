import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Components/Login';
import AISearch from './Components/AISearch';
import { post } from 'aws-amplify/api';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

// Components

// toast.configure();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const setAuth = boolean => {
    setIsAuthenticated(boolean);
  };

  const checkAuthenticated = async () => {
    try {
      // Assuming you have an endpoint to verify the JWT token
      const restOperation = await post({
        apiName: 'march21HandleVerify',
        path: '/auth/verify',
        options: {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('orangeToken')}`
          }
        }
      });
      const apiResponse = await restOperation.response;
      const responseBody = await apiResponse.body.json()
      responseBody === true ? setIsAuthenticated(true) : setIsAuthenticated(false);
    } catch (err) {
      console.error("err: ", err.message);
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    checkAuthenticated();
  }, []);

  return (
    <>
      <Router>
        <div className="container">
          
          <Routes>
            <Route path="/login" element={!isAuthenticated ? <Login setAuth={setAuth} /> : <Navigate replace to="/aisearch" />} /> 
            <Route path="/aisearch" element={isAuthenticated ? <AISearch setAuth={setAuth} /> : <Navigate replace to="/login" />} />
            {/* /* Redirect all other paths to "/login" or a NotFound component */}
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
