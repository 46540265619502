import React, { useState } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';

const BarChartComponent = ({ data }) => {
  const [metric, setMetric] = useState('views');
  const [activeIndex, setActiveIndex] = useState(-1);

  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042']; // Example colors

  // Transform the videoLength from "HH:MM:SS" to total minutes
  const transformVideoLengthToMinutes = (videoLength) => {
    const [hours, minutes, seconds] = videoLength.split(':').map(Number);
    return hours * 60 + minutes + seconds / 60;
  };

  const adjustedData = data.map(item => ({
    ...item,
    views: parseInt(item.views, 10),
    engagementRate: item.engagementRate,
    videoLength: transformVideoLengthToMinutes(item.videoLength)
  }));

  // Custom Tooltip component
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      let value = payload[0].value;
      if (metric === 'engagementRate') {
        value = `${value.toFixed(2)}%`;
      } else if (metric === 'videoLength') {
        value = `${value.toFixed(2)} minutes`;
      } else {
        value = value.toLocaleString();
      }
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
          <p>{`Video: ${payload[0].payload.title}`}</p>
          <p>{`${metric}: ${value}`}</p>
        </div>
      );
    }
    return null;
  };

  // Formatter for YAxis labels based on the selected metric
  const yAxisFormatter = (value) => {
    if (metric === 'engagementRate') {
      return `${value}%`;
    } else if (metric === 'videoLength') {
      return `${value} minutes`;
    }
    return value.toLocaleString();
  };

  return (
    <>
      <h2>Multi-video metrics comparison</h2>
      <div>
        <label htmlFor="metric-select">Choose a metric:</label>
        <select id="metric-select" value={metric} onChange={(e) => setMetric(e.target.value)}>
          <option value="views">Views</option>
          <option value="engagementRate">Engagement Rate</option>
          <option value="videoLength">Video Length</option>
        </select>
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={adjustedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="publishedAt" />
          <YAxis tickFormatter={yAxisFormatter} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey={metric} onMouseOver={(data, index) => setActiveIndex(index)} onMouseOut={() => setActiveIndex(-1)}>
            {adjustedData.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill={colors[index % colors.length]} 
                stroke={activeIndex === index ? '#000' : colors[index % colors.length]} 
                strokeWidth={activeIndex === index ? 2 : 1}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default BarChartComponent;
