import React from 'react';
import styles from './Header.module.css';

const Header = ({ onSignOut, user }) => {

  return (
    <div className={styles.headerContainer}>
        <h2 className={styles.welcomeHeading}>Welcome to Orange AI</h2>
        <button className={styles.signOutButton} onClick={onSignOut}>SIGN OUT</button>
    </div>
  )
}

export default Header
