import React, { useState, useEffect } from 'react';
import styles from './LeftPanel.module.css';

const LeftPanel = ({ setSelectedPanel, setReportData, reports, getSearchHistory }) => {
  const [selectedIndex, setSelectedIndex] = useState(""); // Use an empty string to represent no selection

  useEffect(() => {
    getSearchHistory();
  }, [getSearchHistory]);

  const handleChange = (event) => {
    const index = event.target.value;
    setSelectedIndex(index); // Update the selected index
    const report = reports[index]; // Find the report by index
    if (report) {
      displayContent(report);
    }
  };

  const displayContent = (report) => {
    setReportData(report);
  };

  return (
    <div className={styles.leftPanel}>
      <button className={styles.leftPanelButton} onClick={() => setSelectedPanel('dashboard')}>DASHBOARD</button>
      <label htmlFor="searchHistoryDropdown">Search History:</label>
      <select id="searchHistoryDropdown" value={selectedIndex} onChange={handleChange} className={styles.leftPanelSelect}>
        <option value="">Select a report</option>
        {reports.map((report, index) => (
          <option key={index} value={index}>
            {report.search_dates}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LeftPanel;
