/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "march21DashboardCreateReport",
            "endpoint": "https://a9yi007yna.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "march21DashboardGetRecords",
            "endpoint": "https://3aheu8v3f2.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "march21DashboardGetReport",
            "endpoint": "https://lne1ty1v8a.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "march21FetchRecords",
            "endpoint": "https://s8vx5ndo07.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "march21HandleLogin",
            "endpoint": "https://i8iv5jvxyc.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "march21HandleVerify",
            "endpoint": "https://rc1mjfd5v2.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
