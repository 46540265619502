import React from 'react';

const Table = ({ techStackUsed, technologies }) => {
    // Console logs for debugging
    // console.log('Tech Stack Used:', techStackUsed);
    // console.log('Technologies:', technologies);

    const verticalLineStyle = {
        borderLeft: '2px solid #000',
        margin: '0 20px',
        height: 'auto', // Adjust the height as needed
    };

    return (
        <div className="container">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', alignItems: 'flex-start' }}>
                <div style={{ flex: 1 }}>
                    <h3>Topics Discussed</h3>
                    <ol>
                        {technologies.map((technology, index) => (
                            <li key={index}>{technology}</li>
                        ))}
                    </ol>
                </div>

                {/* Vertical line */}
                <div style={verticalLineStyle}></div>

                <div style={{ flex: 1 }}>
                    <h3>Tech Stack Used</h3>
                    <ol>
                        {techStackUsed.map((tech, index) => (
                            <li key={index}>{tech}</li>
                        ))}
                    </ol>
                </div>
            </div>
            <hr />
            {/* You can add more UI elements below */}
        </div>
    );
};

export default Table;
