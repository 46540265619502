import React, { useState, useRef, useEffect } from 'react';
import styles from './Dashboard.module.css';
import BarChartComponent from './DashboardGraphs/BarChartComponent';
import PieChartComponent from './DashboardGraphs/PieChartComponent';
import Table from './DashboardGraphs/Table';
import Analysis from './DashboardGraphs/Analysis';
import CategorizedFeedback from './DashboardGraphs/CategorizedFeedback';
import CommentAnalysis2 from './CommentAnalysis2';
import { post, get } from 'aws-amplify/api';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';

// Amplify.configure(awsconfig);

const LoadingAnimation = () => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div className={styles.loadingAnimation}></div>
  </div>
);

const Dashboard = ({ user, reportData, getSearchHistory }) => {
  const [isLoading, setIsLoading] = useState(false); // for showing the Loading Text & Loading Animation while data is fetched & analyzed in the backend
  const [statusText, setStatusText] = useState(''); // for showing the Loading Text
  const [videoData, setVideoData] = useState([]) // getting data about stats from the nested object
  const [topicAndStack, setTopicAndStack] = useState({});
  const [topic, setTopic] = useState(['a']);
  const [analysis, setAnalysis] = useState({});
  const [techStack, setTechStack] = useState({});
  const [technologies, setTechnologies] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedMetric, setSelectedMetric] = useState('views');
  const [showCharts, setShowCharts] = useState(false);
  const reportIdRef = useRef(null); // Ref to store the current reportId
  const intervalIdRef = useRef(null);

  // Populating the dashboard with the report response
  const handleReportResponse = (res) => {
    // Parse the response
    getSearchHistory();
    const content = res.reportContent;
    setVideoData(JSON.parse(content.video_stats));
    const topicsAndTech = JSON.parse(content.topicsAndTechStack);
    setTechStack(topicsAndTech.tech_stack.summary);
    setTechnologies(topicsAndTech.technologies.summary);
    setAnalysis(JSON.parse(content.analysis));
    setShowCharts(true);
  };



  // Get report from the backend and handle it
  const pollReportStatus = async (reportId) => {
    try {
      const reportId = reportIdRef.current;
      const pollingGetCall = await get({
        apiName: 'march21DashboardGetRecords',
        path: `/getRecords/${reportId}`
      });
      const pollingResponse = await pollingGetCall.response;
      const responseBody = await pollingResponse.body.json();
      if (responseBody.status === 'COMPLETED') {
        // Fetch the report from the reportUri or update the state with the URI
        setIsLoading(false);
        setStatusText('');
        handleReportResponse(responseBody);
        return true;
      } else if (responseBody.status === 'FAILED') {
        // Handle a failed report generation
        setIsLoading(false);
        setStatusText('');
        alert(`Sorry ${user}, but an error occured :( Would you mind quickly writing this to us so we can improve Orange AI for you?!`);
        return true;
      } else {
        return false;
      }
      // If status is IN_PROGRESS or any other status indicating waiting, do nothing
    } catch (error) {
      console.error('Error polling report status: ', error);
      setIsLoading(false);
      return true
    }
  };

  // Generate report and call the helpr get record
  const getDashboardRecords = async () => {
    try {
      const getCall = await post({
        apiName: 'march21FetchRecords',
        path: '/records',
        options: {
          body: {
            "user": user,
            "startDate": startDate.replaceAll('-', '/'),
            "endDate": endDate.replaceAll('-', '/'),
          },
        },
      });

      const apiResponse = await getCall.response;
      const responseBody = await apiResponse.body.json();
      reportIdRef.current = responseBody.reportId;

      //Begin polling for report status
      intervalIdRef.current = setInterval(async () => {
        const isComplete = await pollReportStatus(reportIdRef.current);
        if (isComplete) {
          clearInterval(intervalIdRef.current); //Stop polling when the report is ready
        }
      }, 5000) //polling every 5 seconds
    } catch (err) {
      console.log('error while processing request');
      console.log(err);
    }

  };

  const handleEnter = () => {
    // Logic for what happens when "Enter" is clicked
    if (startDate === '' || endDate === '') {
      alert('Please enter both Start Date and End Date');
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    if (end < start) {
      alert('Please make sure that the End Date is after the Start Date');
      return;
    }

    setIsLoading(true); // Show loading indicator
    setStatusText('Fetching your data...')
    getDashboardRecords();
  };

  useEffect(() => {
    //Cleanup polling interval when the component is unmounted or reportId changes
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  // Use data from the search history report
  useEffect(() => {
    if (reportData) {
      const content = reportData.report_content;
      setVideoData(JSON.parse(content.video_stats));
      const topicsAndTech = JSON.parse(content.topicsAndTechStack);
      setTechStack(topicsAndTech.tech_stack.summary);
      setTechnologies(topicsAndTech.technologies.summary);
      setAnalysis(JSON.parse(content.analysis));
      setShowCharts(true);
    }
  }, [reportData]);


  return (
    <div className={styles.centerContent}>
      <div className={styles.topLeftCorner}>
        <label className={styles.dateLabel}>
          Start Date:
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>
        <label className={styles.dateLabel}>
          End Date:
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>
        <button onClick={handleEnter}>Enter</button>
      </div>
      {isLoading && <LoadingAnimation />}
      {isLoading && <div className={styles.loadingText}>{statusText}</div>}
      {showCharts && (
        <div className={styles.chartsContainer}>
          <div className={styles.columnOne}>
            <div className={styles.equalWidth}>
              <BarChartComponent data={videoData} />
            </div>
            <div className={`${styles.equalWidth} ${styles.table}`}>
              <Table techStackUsed={techStack} technologies={technologies} />
            </div>
          </div>
          <CommentAnalysis2 data={analysis} />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
