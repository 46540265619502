import React from 'react';
import styles from './VideoDetailBlock.module.css';

const VideoDetailBlock = ({ videoData }) => {
    const videoTitle = videoData.videoTitle;
    const videoPublishedDate = videoData.videoPublishedDate;
    const videoCategory = videoData.videoCategory;
    const videoLength = videoData.videoLength;
    const numViews = videoData.numViews;
    const numComments = videoData.numComments;
    const numLikes = videoData.numLikes
    const numCommentLikes = videoData.numCommentLikes;
    const engagementRate = (videoData.engagementRate).toFixed(2);

    const formatNumber = numberString => {
        return Number(numberString).toLocaleString();
    }

  return (
    <div className={styles.mainBlock}>
        <span className={styles.headingText}>Your insights from <span className={styles.videoTitle}>{videoTitle}</span></span>
        <div className={styles.tokens}>
            <span className={styles.token}>Published {videoPublishedDate}</span>
            <span className={styles.token}>YouTube Category - {videoCategory}</span>
            <span className={styles.token}>{videoLength}</span>
            <span className={styles.token}>{formatNumber(numViews)} views</span>
            <span className={styles.token}>{formatNumber(numComments)} comments</span>
            <span className={styles.token}>{formatNumber(numLikes)} likes</span>
            <span className={styles.token}>{engagementRate}% engagement</span>
        </div>
    </div>
  )
}

export default VideoDetailBlock
