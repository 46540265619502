import React, { useState, useEffect } from 'react';
import Main from './Main';
import styles from './AISearch.module.css'; // Assume you have styles defined

const AISearch = ({ setAuth }) => {
  const [username, setUsername] = useState('')

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const handleSignOut = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('orangeToken');
    setAuth(false); //should automatically redirect to login according to the logic in App.js
  }

  return (
    <div className={styles.aisearchContainer}>
      <Main onSignOut={handleSignOut} user={username} />
    </div>
  )
}

export default AISearch;
