import React from 'react';
import styles from './AnalysisBlock.module.css';

const AnalysisBlock = ({ sentiment, sentimentData }) => {
  const sentimentPercentage = sentimentData.percent;
  const actionItems = sentimentData.action_items.slice(0, 9);

  let blockHeading, headingStyle;
  switch (sentiment) {
    case 'positive_comments':
      blockHeading = 'Audience Liked - Maintain & Grow!';
      headingStyle = styles.positive;
      break;
    case 'neutral_comments':
      blockHeading = 'Audience Requests';
      headingStyle = styles.neutral;
      break;
    case 'negative_comments':
      blockHeading = 'Improvement Opportunities';
      headingStyle = styles.negative;
      break;
    default:
      blockHeading = '';
      headingStyle = '';
  }
  
  return (
    <div className={styles.sentimentBlock} key={sentiment}>
        <h3 className={headingStyle}>{blockHeading}</h3>
        <p className={styles.typing}>Frequency in comments - {sentimentPercentage}%</p>
        <h4 className={styles.typing}>Action Items</h4>
        <ol className={styles.actionItems}>
          {actionItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
    </div>
  )
}

export default AnalysisBlock;
